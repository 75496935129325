<template>
    <div
        :class="[rootClasses, { 'bg-color': data.hasBackground == true }, { 'position-absolute-custom': data.hasBackground == false }]">
        <div class="container">
            <nav class="navbar navbar-light navbar-expand-xl flex-wrap cs-header-nav-navbar">
                <a class="navbar-brand cs-header-nav-brand" href="#">
                    <img class="logo" :src="data.logo" alt="..." />
                </a>
                <button class="navbar-toggler cs-header-nav-toggler" type="button" data-toggle="collapse"
                    data-target="#navbarText" aria-controls="navbarText" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#2E2F3A" class="bi bi-list"
                            viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z">
                            </path>
                        </svg>
                    </span>
                </button>
                <div class="collapse navbar-collapse cs-header-nav-collapse" id="navbarText">
                    <ul class="navbar-nav">
                        <li v-for="(li, key) in menu" :key="key">
                            <span v-if="Object.keys(li.children).length == 0" class="nav-item active">
                                <a class="nav-link" :href="li.url">{{ link(li.title) }}</a>
                            </span>
                            <span v-if="Object.keys(li.children).length > 0" class="nav-item dropdown active">
                                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {{ link(li.title) }}
                                </a>
                                <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                    <a v-for="(subitem, key1) in li.children" :key="key1" class="dropdown-item"
                                        :href="subitem.url">{{ link(subitem.title) }}</a>
                                </div>
                            </span>
                        </li>
                    </ul>
<!-- 
                    <a :href="data.links[0].url" class="register-button">
                        <i class="bi bi-person"></i>
                        <span>{{ data.links[0].linkText }}</span>
                    </a> -->
                </div>
            </nav>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "Menu",
    components: {
        HeaderMenuBlockGroup: () =>
            import("../blocks_components/HeaderMenuBlockGroup.vue"),
    },
    data() {
        return {
            menu: [],
            uniqueId: "id" + Date.now(),
            build: {},
            isLoggedIn: false,
        };
    },
    props: {
        data: {
            type: Object,
            default: () => { },
        },
    },
    methods: {
        link(link) {
            return link.charAt(0).toUpperCase() + link.slice(1, -1);
        },
    },
    mounted() {
        console.log('Header menu data:', this.data);
    },
    computed: {
        rootClasses() {
            let result = {
                ["cs-header-nav"]: true,
            };

            if (this.data?.hasBackground)
                result = {
                    ...result,
                    ...{
                        ["has-background"]: true,
                        ["theme-bg-colored"]: true,
                    },
                };

            return result;
        },
    },
};
</script>
  
<style lang="scss" scoped>
@import "../styles/main.scss";

$link_horizontal_padding: 35px;
$link_horizontal_padding_smaller: 24px;
$link_horizontal_padding_bs_def: 1rem;

.position-absolute-custom {
    padding: 35px 0;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 999;
    background-color: transparent;

    @media (max-width: 1199.98px) {
        position: relative;
        background-color: $accent_bg_color;
    }

    .cs-header-nav {
        position: relative;
        z-index: 999;
        background-color: $theme_bg_color;

        &-navbar {
            padding: 15px 0;
        }

        &-collapse {
            ul {
                display: flex;
                align-items: flex-start;
                justify-content: flex-end;
                flex-wrap: wrap;
                width: 100%;

                li {
                    margin: 0;
                    padding: 0 !important;

                    @media (max-width: 1199.98px) {
                        padding-bottom: 10px !important;
                        width: 100%;
                        // border-bottom: 1px solid $labels_color;
                    }

                    .dropdown-toggle {
                        @include theme-link-inverted;
                        font-weight: 700;
                        font-size: 14px;
                        padding-left: 0 !important;
                        padding-right: 0 !important;
                        padding: 0.5rem 20px !important;
                        color: $secondary_text_color !important;

                        @media (max-width: 1400px) {
                            padding: 0.5rem $link_horizontal_padding_smaller;
                        }

                        @media (max-width: 1199.98px) {
                            padding: 0px !important;
                            margin-top: 15px;
                        }
                    }

                    .nav-link {
                        @include theme-link-inverted;
                        font-weight: 500;
                        font-size: 14px;
                        padding-left: 0 !important;
                        padding-right: 0 !important;
                        padding: 0.5rem 20px !important;

                        white-space: nowrap;
                        overflow: hidden;
                        max-width: 120px;

                        @media (min-width: 1200px) {
                            color: $text_color_over_theme_color !important;
                        }

                        @media (max-width: 1199.98) {
                            max-width: 80vw;
                            color: $secondary_text_color !important;
                        }

                        text-overflow: ellipsis;

                        @media (max-width: 1400px) {
                            padding: 0.5rem $link_horizontal_padding_smaller;
                        }

                        @media (max-width: 1199.98px) {
                            padding: 0px !important;
                            margin-top: 15px;
                        }
                    }
                }
            }

            @media (max-width: 1199.98px) {
                .register-button {
                    margin-top: 30px;
                    display: inline-block;
                }

                padding-bottom: 30px;
            }
        }

        &-extra {
            margin-left: 50px;

            @media (max-width: 1400px) {
                margin-left: calc(120px - $link_horizontal_padding_smaller);
            }

            @media (max-width: 1199.98px) {
                margin-left: 0px;
            }

            * {
                @include title;
                color: $secondary_text_color !important;
                font-size: 18px;
            }
        }
    }

    .register-button {
        @include theme-button;
        word-break: break-word;
        min-width: 210px;
    }
}

.bg-color {
    background-color: $theme_color;
    color: $text_color_over_theme_color;
    padding: 15px 0;

    .navbar-toggler {
        border-color: $text_color_over_theme_color !important;
        color: $text_color_over_theme_color !important;

        svg {
            fill: $text_color_over_theme_color !important;
        }
    }

    .cs-header-nav {
        position: relative;
        z-index: 999;
        background-color: $theme_bg_color;

        &-navbar {
            padding: 15px 0;
        }

        &-collapse {
            ul {
                display: flex;
                align-items: flex-start;
                justify-content: flex-end;
                flex-wrap: wrap;
                width: 100%;

                li {
                    margin: 0;
                    padding: 0 !important;

                    @media (max-width: 1199.98px) {
                        padding-bottom: 10px !important;
                        width: 100%;
                        // border-bottom: 1px solid $labels_color;
                    }

                    .dropdown-toggle {
                        @include theme-link-inverted;
                        font-weight: 700;
                        font-size: 14px;
                        padding-left: 0 !important;
                        padding-right: 0 !important;
                        padding: 0.5rem 20px !important;
                        color: $text_color_over_theme_color !important;

                        @media (max-width: 1400px) {
                            padding: 0.5rem $link_horizontal_padding_smaller;
                        }

                        @media (max-width: 1199.98px) {
                            padding: 0px !important;
                            margin-top: 15px;
                            color: $text_color_over_theme_color !important;
                        }
                    }

                    .nav-link {
                        @include theme-link-inverted;
                        font-weight: 500;
                        font-size: 14px;
                        padding-left: 0 !important;
                        padding-right: 0 !important;
                        padding: 0.5rem 20px !important;

                        white-space: nowrap;
                        overflow: hidden;
                        max-width: 120px;

                        @media (min-width: 1200px) {
                            color: $text_color_over_theme_color !important;
                        }

                        @media (max-width: 1199.98) {
                            max-width: 80vw;
                            color: $text_color_over_theme_color !important;
                        }

                        text-overflow: ellipsis;

                        @media (max-width: 1400px) {
                            padding: 0.5rem $link_horizontal_padding_smaller;
                        }

                        @media (max-width: 1199.98px) {
                            padding: 0px !important;
                            margin-top: 15px;
                            color: $text_color_over_theme_color !important;
                        }
                    }
                }
            }

            @media (max-width: 1199.98px) {
                .register-button {
                    margin-top: 30px;
                    display: inline-block;
                }

                padding-bottom: 30px;
            }
        }

        &-extra {
            margin-left: 50px;

            @media (max-width: 1400px) {
                margin-left: calc(120px - $link_horizontal_padding_smaller);
            }

            @media (max-width: 1199.98px) {
                margin-left: 0px;
            }

            * {
                @include title;
                color: $secondary_text_color !important;
                font-size: 18px;
            }
        }
    }

    .register-button {
        @include theme-button;
        color: $theme-color;
        background-color: $text_color_over_theme_color;
        word-break: break-word;
        min-width: 210px;

        &:hover {
            background-color: $theme-color;
            color: $text_color_over_theme_color;
            border: 2px solid $text_color_over_theme_color;
        }
    }
}

.cs-header-nav {

    @media (max-width: 1199.98px) {
        padding: 15px 0;
    }

    .logo {
        width: 100px;
        height: 50px;
        object-fit: contain;
        object-position: left;
    }
}

::v-deep {



    .dropdown-toggle::after {
        color: $theme_color;
    }

    .cs-header-nav-navbar {
        padding: 0 !important;
        // position: relative;
    }



    .navbar-brand {
        display: none;

        @media (max-width: 1199.98px) {
            display: block;
        }
    }

    .navbar-toggler {
        border-color: $theme_color;
        color: $theme_color ;

        &:focus-visited {
            box-shadow: none;
            outline: none;
        }

        svg {
            fill: $theme_color;
        }
    }

    .navbar-nav {
        li {
            background: none;
        }
    }

    .dropdown-menu {
        // padding: 0 !important;
        background: $theme_color;

        @media (max-width: 1199.98px) {
            background-color: transparent;
        }

        border: none;
        // border-radius: 0;
    }

    .dropdown-item {
        color: $text_color_over_theme_color !important;
        padding: 0.5rem 1.2rem;

        &:hover {
            color: $theme_color !important;
        }

        @media (max-width: 1199.98px) {
            color: $primary_text_color !important;
        }
    }


}
</style>
  